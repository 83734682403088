import {
  ProjectDocumentRuleType,
  ProjectGridFieldRuleType,
} from '@/types/rules'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { FormCheckbox } from '@/components/forms'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import DependentFieldsAutocomplete from '@/components/project-settings/field-rules/DependentFieldsAutocomplete'
import PropertyField from '@/components/project-settings/params-schema-property-fields/PropertyField'
import RuleWorkflowStates from './RuleWorkflowStates'

type CommonRuleConfigurationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertiesToRender: [string, any][]
  ruleType: ProjectGridFieldRuleType | ProjectDocumentRuleType
  fieldId?: string
}

// To make TS happy
function isProjectGridFieldRuleType(
  type: ProjectGridFieldRuleType | ProjectDocumentRuleType,
): type is ProjectGridFieldRuleType {
  return 'uses_dependent_fields' in type
}

export default function CommonRuleConfiguration({
  propertiesToRender,
  ruleType,
  fieldId,
}: CommonRuleConfigurationProps) {
  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()
  const featureFlags = useFeatureFlagContext()

  return (
    <>
      {isProjectGridFieldRuleType(ruleType) &&
        ruleType.uses_dependent_fields && (
          <DependentFieldsAutocomplete ruleType={ruleType} fieldId={fieldId} />
        )}
      {propertiesToRender.map(([propertyId, property]) => {
        const required = ruleType.params_schema.required?.includes(propertyId)
        return (
          <PropertyField
            key={`${ruleType.id}-${propertyId}`}
            propertyId={propertyId}
            property={property}
            required={required}
          />
        )
      })}
      {isSuperUser && !demoMode && ruleType.code === 'run_py' && (
        // Wrapping in div to "skip" default MUI margins
        <div>
          <FormCheckbox
            name="is_superuser_rule"
            label="Super User Only"
            sx={{
              color: SUPER_USER_ONLY_COLOR,
              '&.Mui-checked': {
                color: SUPER_USER_ONLY_COLOR,
              },
            }}
          />
        </div>
      )}
      {featureFlags.rules_in_workflow_states && <RuleWorkflowStates />}
    </>
  )
}

import { useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { DocumentRowValue } from '@/types/documents'

type FieldInfoExtraDataProps = {
  metadata: NonNullable<DocumentRowValue['metadata']>
}

export default function FieldInfoExtraData({
  metadata,
}: FieldInfoExtraDataProps) {
  const [showData, setShowData] = useState(false)

  return (
    <Stack
      flexGrow={1}
      py={1.5}
      px={1}
      onMouseDown={(e) => e.preventDefault()}
      sx={{
        overflowX: 'auto',
      }}
    >
      {!showData && (
        <Button
          onClick={() => {
            setShowData(true)
          }}
          sx={{ width: 'fit-content' }}
          variant="text"
        >
          Show Extra Data
        </Button>
      )}
      {showData && (
        <>
          <Typography component="h3" variant="subtitle1" sx={{ px: 0.5 }}>
            Extra Data
          </Typography>
          {Object.entries(metadata).map(([key, value]) => {
            if (key === 'confidence' || key === 'weighted_confidence') return
            return (
              <Typography key={key} component="pre" variant="body2">
                {JSON.stringify(value, null, 2)}
              </Typography>
            )
          })}
        </>
      )}
    </Stack>
  )
}

import { FiberManualRecord } from '@mui/icons-material'
import { Button } from '@mui/material'
import { RowValueFlag } from '@/types/flags'
import useOverlay from '@/hooks/useOverlay'
import { getFlagsCount } from '@/utils/flags-utils'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useDocumentRowValuesContext } from '@/components/validation/providers/DocumentRowValuesProvider'
import { OptionsKeyType } from '@/components/validation/VisibleFieldsControl'
import FlagsPopover from './FlagsPopover'
import useFlagPriorityColor from './useFlagPriorityColor'

type ValidationFlagsButtonProps = {
  selectedOptionKey: string
  setSelectedOptionKey: (key: OptionsKeyType) => void
}

export default function ValidationFlagsButton({
  selectedOptionKey,
  setSelectedOptionKey,
}: ValidationFlagsButtonProps) {
  const overlay = useOverlay()

  const { rowValueFlags } = useDocumentRowValuesContext()

  const { document } = useDocumentContext()
  const { document_flags = [] } = document || {}

  const documentFlagsCount = getFlagsCount(document_flags)
  const rowFlagsCount = getFlagsCount(rowValueFlags)

  const { color } = useFlagPriorityColor({
    documentFlags: documentFlagsCount,
    rowsFlags: rowFlagsCount,
  })

  const totalLength = document_flags.length + rowValueFlags.length

  function onRowValueFlagClick(
    flag: RowValueFlag,
    cellToFocus: HTMLInputElement | HTMLDivElement | null,
  ) {
    if (!cellToFocus && selectedOptionKey === 'relevant') {
      setSelectedOptionKey('all')
      setTimeout(() => {
        let cellToFocus: HTMLInputElement | HTMLDivElement | null =
          window.document.querySelector(
            `[data-focusid="${flag.document_row_value_id}"] input`,
          )

        if (cellToFocus) {
          if ('disabled' in cellToFocus && cellToFocus.disabled) {
            cellToFocus =
              cellToFocus.closest('div.focus-container') || cellToFocus
          }
          cellToFocus.focus()
        }
      }, 0)
    }
    overlay.close()
  }

  if (!totalLength) {
    return null
  }

  return (
    <>
      <Button
        color={color?.button}
        variant="text"
        startIcon={<FiberManualRecord sx={{ fontSize: 12 }} />}
        onClick={overlay.open}
        sx={{ flexShrink: 0 }}
      >
        {totalLength} Flag{totalLength > 1 ? 's' : ''}
      </Button>

      <FlagsPopover
        overlay={overlay}
        rowValueFlags={rowValueFlags}
        documentFlags={document_flags}
        onRowValueFlagClick={onRowValueFlagClick}
      />
    </>
  )
}

import { useRef } from 'react'
import { Visibility } from '@mui/icons-material'
import { Box, Button, Divider, Menu, MenuItem, Stack } from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import ValidationFlagsButton from '@/components/flags/ValidationFlagsButton'
import DocProjectTagsList from '../project-tags/DocProjectTagsList'
import { useDocumentContext } from '../image-zoom-pan/providers/DocumentProvider'

export type OptionsKeyType = keyof typeof options

const options = {
  relevant: 'Relevant Fields',
  all: 'All Fields',
  flagged: 'Fields with Flags',
}

export default function VisibleFieldsControl() {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const { document } = useDocumentContext()

  const [selectedOptionKey, setSelectedOptionKey] = useLocalStorage<
    keyof typeof options
  >('validation-visible-fields-option', 'relevant')

  return (
    <>
      <Divider sx={{ my: 1 }} />
      <Stack
        direction="row"
        flexWrap="wrap"
        columnGap={1.5}
        rowGap={0.5}
        alignItems="center"
      >
        <ValidationFlagsButton
          selectedOptionKey={selectedOptionKey}
          setSelectedOptionKey={setSelectedOptionKey}
        />

        <Button
          startIcon={<Visibility />}
          onClick={overlay.toggle}
          ref={anchorRef}
          variant="text"
        >
          {options[selectedOptionKey]}
        </Button>
        <Menu
          open={overlay.isOpen}
          onClose={overlay.close}
          anchorEl={anchorRef.current}
          elevation={16}
        >
          {Object.entries(options).map(([key, value]) => (
            <MenuItem
              dense
              key={key}
              onClick={() => {
                setSelectedOptionKey(key as keyof typeof options)
                overlay.close()
              }}
              selected={key === selectedOptionKey}
            >
              {value}
            </MenuItem>
          ))}
        </Menu>

        <Box flexGrow={1}>
          <DocProjectTagsList
            docProjectTags={document?.doc_project_tags || []}
          />
        </Box>
      </Stack>
    </>
  )
}
